import { makeStyles } from "@mui/styles";

export const FiltrosStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5em",
    },
    filtros: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
    },
    inputContainer: {
        position: "relative",
        "& .MuiFormLabel-root": {
            fontSize: "1.2rem"
        }
    },
    input: {
        flexDirection: "row",
        marginRight: "1rem",
        // width: "300px",
        maxWidth: "350px",
        "& .MuiInputBase-root": {
            width: "auto",
        },
    },
    iconoBorrar: {
        height: "16px",
        width: "16px",
        position: "absolute",
        right: "2rem",
        bottom: "16px",
        cursor: "pointer",
    },
    total: {
        margin: 0,
        height: "4rem",
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        paddingBottom: "6px",
        boxSizing: "border-box",
    },
    marginNormal: {
        height: "48px",
        marginBottom: "8px",
        marginTop: "16px",
        display: "flex",
        alignItems: "flex-end",
    },
    button: {
        width: "100px",
        backgroundColor: "#87B867 !important",
    },
}));
