import axiosPost from "./axiosPost";

export const docentesGet = async (credentials, data) => {
    const body = {
        service: "NQNDocenteGet",
        params: {
            nqnDocenteId: data?.id,
            nqnDocenteNroDeDocumento: data?.doc,
            nqnEscuelaId: data?.escuelaId,
            nqnCargoId: data?.cargoId,
            nqnDocenteStatus: data?.estadoId,
            page: data?.page,
            pageSize: data?.pageSize,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return {result: result.result,
                total: result.status.id
        };
    }
};
