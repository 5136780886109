import { React, useState, useEffect } from "react";

import { Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import { InsideDrawerNav } from "./InsideDrawerNav";

import { Routes, servicesRoutes } from "consts/Routes-App";

import { useRecoilValue } from "recoil";
import { rioNegroServices } from "recoilState/GlobalState";

import styles from "./ListDrawer.module.scss";
import { ExpandLess, ExpandMore, HomeRounded, CalendarMonthRounded, ApartmentRounded, Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";

import PropaneTankIcon from '@mui/icons-material/PropaneTank';
export default function ListDrawer({ setOpen }) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const services = useRecoilValue(rioNegroServices);

    const handleClick = () => {
        setOpen(false);
    };

    useEffect(() => {
        // setIsDrawerOpen(true)
    }, [])

    return (
        <>
            {services && (
                <List
                    aria-labelledby="nested-list-subheader"
                    className={styles.root}
                    component="nav"
                >
                    <Link to={Routes.dashboard.route}>
                        <ListItem
                            className={styles.itemList}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <HomeRounded className={styles.iconsDrawer} />
                            </ListItemIcon>
                            <span className={styles.textColor}>Home</span>
                        </ListItem>
                    </Link>
                    <span>
                        <ListItemButton
                            className={styles.itemList}
                            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        >
                            <ListItemIcon>
                                <Icon>
                                    <Settings sx={{ color: "#FFFFFF" }} />
                                </Icon>
                            </ListItemIcon>
                            <ListItemText className={styles.textColor}>
                                Configuracion
                            </ListItemText>
                            {isDrawerOpen ? <ExpandLess style={{ color: "#FFFFFF" }} /> : <ExpandMore style={{ color: "#FFFFFF" }} />}
                        </ListItemButton>
                        <Collapse in={isDrawerOpen} timeout="auto">
                            <List component="div">
                                <Link to={Routes.feriados.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <CalendarMonthRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Feriados</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.feriadosSerie.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <CalendarMonthRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Serie de feriados</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.calendarios.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <CalendarMonthRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Calendarios</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.cicloLectivo.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <CalendarMonthRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Ciclos lectivos</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.distritos.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <ApartmentRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Distritos escolares</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.niveles.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <ApartmentRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Niveles</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.escuelas.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <ApartmentRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Escuelas</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.cargos.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <ApartmentRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Cargos</span>
                                    </ListItem>
                                </Link>
                                <Link to={Routes.docentes.route}>
                                    <ListItem
                                        className={styles.itemList}
                                        onClick={handleClick}
                                    >
                                        <ListItemIcon>
                                            <ApartmentRounded className={styles.iconsDrawer} />
                                        </ListItemIcon>
                                        <span className={styles.textColor}>Docentes</span>
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </span>
                    <Link to={Routes.eventos.route}>
                        <ListItem
                            className={styles.itemList}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <ApartmentRounded className={styles.iconsDrawer} />
                            </ListItemIcon>
                            <span className={styles.textColor}>Eventos</span>
                        </ListItem>
                    </Link>
                    {/* <Link to={Routes.usuarios.route}>
                        <ListItem
                            className={styles.itemList}
                            onClick={handleClick}
                        >
                            <ListItemIcon>
                                <ApartmentRounded className={styles.iconsDrawer} />
                            </ListItemIcon>
                            <span className={styles.textColor}>Usuarios</span>
                        </ListItem>
                    </Link> */}
                </List>
            )}
        </>
    );
}
