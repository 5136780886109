import React from "react";

import { Button } from "@mui/material";

import { useRecoilValue, useRecoilState } from "recoil";
import { modalData, snackbarData } from "recoilState/GlobalState";

import styles from "./FormButton.module.scss";

export default function FormButton({
    onClick,
    onSecondaryClick,
    inRequest,
    newFormData,
    isConfirmationModal,
    hasConfirmationModal,
    closeButtonText,
    oneButton,
    onCloseModal = () => { },
    alert = false
}) {
    const { open } = useRecoilValue(snackbarData);
    const [modal, setModal] = useRecoilState(modalData);

    const formData = newFormData ?? modal

    const className = alert || (!isConfirmationModal &&
        (formData.title?.includes("Eliminar")
            || formData.title?.includes("Cancelar")
            || formData.button?.includes("Eliminar")
            || formData.button?.includes("Cancelar"))
    )
        ? styles.redBtn
        : styles.defaultBtn;

    const handleSecondaryButton = () => {
        if (onSecondaryClick) {
            onSecondaryClick()
            return;
        }
        /* 
            Checking if the modal is a confirmation modal, if it is, it will close
            the confirmation modal. If it is not, it will check if the modal has a
            confirmation modal, if it does, it will open the confirmation modal. If
            it does not, it will close the modal. 
        */
        if (isConfirmationModal) {
            setModal({
                ...modal,
                confirmationModalOpen: false,
            })
            return;
        }
        if (hasConfirmationModal) {
            setModal({
                ...modal,
                confirmationModalOpen: true,
            })
            return;
        }
        setModal({
            isOpen: false,
        })
        onCloseModal()
    }

    return (
        <div className={styles.container}>
            <Button
                type="submit"
                disabled={inRequest || open}
                className={className}
                onClick={onClick}
            >
                <span>{formData.button ?? ""}</span>
            </Button>
            {!oneButton && <Button
                disabled={inRequest || open}
                className={styles.whiteBtn}
                onClick={handleSecondaryButton}
            >
                <span>{closeButtonText ?? "Cancelar"}</span>
            </Button>}
        </div>
    );
}
