import axiosPost from "./axiosPost";

export const docentesCheck = async (credentials, data) => {
    const body = {
        service: "NQNDocenteCheck",
        params: {
            nqnDocenteNroDeDocumento: data.doc.replaceAll(".", ""),
            nqnDocenteSexo: data.sexo,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
