import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, StatusModal } from "./Actions";
import { Button, Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, EditOutlined, CalendarMonthRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import moment from 'moment';
import { distritosGet } from 'services/services-mia/distritosGet';
import { docentesGet } from 'services/services-mia/docentesGet';
import styles from "./Docentes.module.scss"
import XGrid from 'components/UI/Grid/XGrid';
import Filters from 'components/Filters';
import { useFormik } from 'formik';
import { escuelasGet } from 'services/services-mia/escuelasGet';
import { cargosGet } from 'services/services-mia/cargosGet';
import XGridServer from 'components/UI/Grid/XGridServer';

export default function Docentes() {
    const titles = [
        {
            field: "lastname",
            headerName: "Apellidos",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "name",
            headerName: "Nombres",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "docFormat",
            headerName: "DNI",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "escuelaNombre",
            headerName: "Escuela",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "cargoNombre",
            headerName: "Cargo",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "tipoCargo",
            headerName: "Tipo de cargo",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "horas",
            headerName: "Horas",
            headerAlign: "right",
            flex: 1,
            maxWidth: 80,
            align: "right",
        },
        {
            field: "status",
            headerName: "Estado",
            flex: 1,
            maxWidth: 100,
        },
        {
            field: "fechaStatus",
            headerName: "Fecha",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "esDirectivo",
            headerName: "Directivo",
            // flex: 1,
            minWidth: 50,
        },
        {
            field: "calendario",
            headerName: "",
            // flex: 1,
            minWidth: 50,
            renderCell: (params) => cellBtn(params)
        },
    ];

    const credentials = useRecoilValue(userCredentials);
    // const setRoute = useSetRecoilState(routeInfo);
    const [editable, setEditable] = useState(false);
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [roles, setRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} });
    const [resetTable, setResetTable] = useState(false);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [escuelas, setEscuelas] = useState([]);
    const [docentes, setDocentes] = useState([]);
    const [distritos, setDistritos] = useState([])
    const [cargos, setCargos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            escuelaId: "",
            doc: "",
            estadoId: "A",
            cargoId: "",
        },
        onSubmit: (values) => {
            updateList()
        },
    });

    const getDocentesData = async (page, pageSize) => {
        setDocentes([]);
        setIsLoading(true)
        const data = {
            rolCod: null,
            page: page + 1,
            pageSize,
            usuarioTieneApp: null,
            usuarioNroDeDocumentoX: formik.values.usuarioNumeroDeDocumentoX || null,
            usuarioApellidos: formik.values.usuarioApellidos || null,
            merchantServicioUsuarioStatus: formik.values.merchantServicioUsuarioStatus || null,
            escuelaId: formik.values.escuelaId,
            doc: formik.values.doc.replaceAll(".", ""),
            estadoId: formik.values.estadoId,
            cargoId: formik.values.cargoId,
        }
        const { result, total } = await docentesGet(credentials, data);
        console.log(result)
        const newRows = result ? result.map((item) => {
            return {
                id: item.nqnDocenteId,
                name: item.usuarioNombres,
                lastname: item.usuarioApellidos,
                dni: item.nqnDocenteNroDeDocumento,
                docFormat: new Intl.NumberFormat("de-DE").format(item.nqnDocenteNroDeDocumento),
                genero: item.nqnDocenteSexo === "M" ? "Masculino" : item.nqnDocenteSexo === "F" ? "Femenino" : "No binario",
                escuelaNombre: item.nqnEscuelaNombre,
                cargoNombre: item.nqnCargoNombre,
                tipoCargo: item.nqnCargoTipo === "C" ? "Horas cátedra" : item.nqnCargoTipo === "S" ? "Horas semanales" : "Horas diarias",
                status: item.nqnDocenteStatus === "A" ? "Alta" : "Baja",
                fechaStatus: item.nqnDocenteStatusFecha ? moment(item.nqnDocenteStatusFecha).format("DD/MM/YYYY").toString() : null,
                horas: item.nqnDocenteHoras,
                calendario: [{ day: "Lunes", hrs: item.nqnDocenteHorasLu }, { day: "Martes", hrs: item.nqnDocenteHorasMa }, { day: "Miercoles", hrs: item.nqnDocenteHorasMi }, { day: "Jueves", hrs: item.nqnDocenteHorasJu }, { day: "Viernes", hrs: item.nqnDocenteHorasVi }],
                horasLu: item.nqnDocenteHorasLu,
                horasMa: item.nqnDocenteHorasMa,
                horasMi: item.nqnDocenteHorasMi,
                horasJu: item.nqnDocenteHorasJu,
                horasVi: item.nqnDocenteHorasVi,
                esDirectivo: item.nqnCargoEsDirectivo === "Y" ? "Si" : "No"
            };
        }) : [];
        setIsLoading(false)
        return {
            rows: newRows,
            rowCount: total
        }

    }
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const updateList = useCallback(() => {
        setRowSelected({ status: false, row: {} })
        setForceUpload((value) => !value);
    }, [])
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };

    // const [modalDetails, setModalDetails] = useState({ isOpen: false });
    // const [distritos, setDistritos] = useState([])
    // const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    // const [connectionRoles, setConnectionRoles] = useState([]);
    // const [forceUpload, setForceUpload] = useState(false);
    // const service = useService()
    // const credentials = useRecoilValue(userCredentials);
    // const [escuelas, setEscuelas] = useState([]);
    // const [docentes, setDocentes] = useState([]);
    // const [cargos, setCargos] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const cellBtn = (params) => {
        return (
            <div className={styles.container}>
                <div className={styles.containerBtn}>
                    <CalendarMonthRounded />
                </div>
                <div className={styles.containerHover}>
                    {params?.value?.map((item, index) => (
                        <div key={index} className={styles.containerData}>
                            <p>{item.day}</p>
                            <p>{item.hrs ? item.hrs : 0}hs</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }


    // const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])



    //     const getDocentesData = async (borrado = false, page, pageSize) => {
    //         setDocentes([]);
    //         setIsLoading(true)
    //         let result
    //         if (borrado) {
    //             result = await docentesGet(credentials, {
    //                 escuelaId: "",
    //                 doc: "",
    //                 estadoId: "",
    //                 cargoId: "",
    //                 page: page,
    //                 pageSize: pageSize,
    //             })
    //         } else {
    //             result = await docentesGet(credentials, {
    //                 escuelaId: formik.values.escuelaId,
    //                 doc: formik.values.doc.replaceAll(".", ""),
    //                 estadoId: formik.values.estadoId,
    //                 cargoId: formik.values.cargoId,
    //                 page: page,
    //                 pageSize: pageSize,
    //             })
    //         }
    //         setTotal(result.totalRows)
    //         const newRows = await result.rows.map((item) => {
    //             return {
    //                 id: item.nqnDocenteId,
    //                 name: item.usuarioNombres,
    //                 lastname: item.usuarioApellidos,
    //                 dni: item.nqnDocenteNroDeDocumento,
    //                 docFormat: new Intl.NumberFormat("de-DE").format(item.nqnDocenteNroDeDocumento),
    //                 genero: item.nqnDocenteSexo === "M" ? "Masculino" : item.nqnDocenteSexo === "F" ? "Femenino" : "Otro",
    //                 escuelaNombre: item.nqnEscuelaNombre,
    //                 cargoNombre: item.nqnCargoNombre,
    //                 tipoCargo: item.nqnCargoTipo === "C" ? "Horas cátedra" : item.nqnCargoTipo === "S" ? "Horas semanales" : "Horas diarias",
    //                 status: item.nqnDocenteStatus === "A" ? "Alta" : "Baja",
    //                 fechaStatus: item.nqnDocenteStatusFecha ? moment(item.nqnDocenteStatusFecha).format("DD/MM/YYYY").toString() : null,
    //                 horas: item.nqnDocenteHoras,
    //                 calendario: [{ day: "Lunes", hrs: item.nqnDocenteHorasLu }, { day: "Martes", hrs: item.nqnDocenteHorasMa }, { day: "Miercoles", hrs: item.nqnDocenteHorasMi }, { day: "Jueves", hrs: item.nqnDocenteHorasJu }, { day: "Viernes", hrs: item.nqnDocenteHorasVi }],
    //                 horasLu: item.nqnDocenteHorasLu,
    //                 horasMa: item.nqnDocenteHorasMa,
    //                 horasMi: item.nqnDocenteHorasMi,
    //                 horasJu: item.nqnDocenteHorasJu,
    //                 horasVi: item.nqnDocenteHorasVi,
    //             };
    //         });
    //         setIsLoading(false)
    //         setDocentes(newRows)
    //         // const esc = await escuelasGet(credentials)
    //         // const car = await cargosGet(credentials)
    //         // const distritos = await distritosGet(credentials)
    //         // setEscuelas(esc)
    //         // setCargos(car)
    //         // setDistritos(distritos)
    //         return {
    //             rows: newRows,
    //             rowCount: newRows.length,
    //         };
    //     }
    //     const handleClick = ({ procede, title }) => {
    //         setModalDetails({ procede, title, isOpen: true });
    //     };
    //     const closeModal = () => {
    //         setModalDetails({ isOpen: false });
    //     };
    const resetForm = () => {
        formik.setFieldValue("doc", "")
        formik.setFieldValue("escuelaId", "")
        formik.setFieldValue("estadoId", "")
        formik.setFieldValue("cargoId", "")
        updateList()
    };
    const getFilters = async () => {
        const esc = await escuelasGet(credentials)
        const car = await cargosGet(credentials)
        const distritos = await distritosGet(credentials)
        setEscuelas(esc)
        setCargos(car)
        setDistritos(distritos)
    }
    useEffect(() => {
        getFilters()
    }, [])
    return (
        <main className={s.main}>
            <Modal mid={modalDetails.procede === ACTIONS.INSERT} open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        // servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "escuelaId", label: "Escuela", noSelect: true, options: escuelas, firtsOptionKey: "nqnEscuelaId", firstOptionName: "nqnEscuelaNombre", firstOptionValue: "nqnEscuelaId" },
                                { type: "text", name: "doc", label: "DNI" },
                                { type: "select", name: "estadoId", label: "Estado", noSelect: true, options: [{ name: "Alta", value: "A" }, { name: "Baja", value: "B" }], firtsOptionKey: "name", firstOptionName: "name", firstOptionValue: "value" },
                                { type: "select", name: "cargoId", label: "Cargo", noSelect: true, options: cargos, firtsOptionKey: "nqnCargoId", firstOptionName: "nqnCargoNombre", firstOptionValue: "nqnCargoId" },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <Button onClick={() => resetForm()} disabled={isLoading}>Borrar</Button>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { setForceUpload(!forceUpload); }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Asignar"}
                        handleClick={handleClick}
                        data={{
                            title: "Asignar Docente",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Docente",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGridServer
                    titles={titles}
                    updateList={getDocentesData}
                    forceUpload={forceUpload}
                    // forceReset={resetTable}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    )
}
