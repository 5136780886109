import React, { useEffect, useState } from "react";

import { useRecoilValue } from "recoil";
import {
    userCredentials,
} from "recoilState/GlobalState";
import { escuelasStatusGet } from "services/services-mia/escuelasStatusGet";
import { CircularIndeterminate, FormButton } from "components";
import { Box } from "@mui/material";
import moment from "moment";

export default function HistoryModal({ user, servicio, closeModal }) {
    const credentials = useRecoilValue(userCredentials);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getData = async () => {
        setIsLoading(true);
        const result = await escuelasStatusGet(credentials, { id: user.id })
        setData(result);
        setIsLoading(false);
    }

    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            {isLoading ? <CircularIndeterminate /> : <Box display={"flex"} justifyContent={"center"} width={"600px"} gap={2} marginBlock={"10px"}>
                <div style={{ width: "100%", borderRadius: "10px", border: "1px solid #E5E9F0" }}>
                    <div style={{ backgroundColor: "#eee", display: "flex", justifyContent: "space-between", padding: "12px", fontSize: "14px", color: "#595959" }}>
                        <div style={{ display: "flex", flex: "2", justifyContent: "start", width: "100%" }}><p style={{ margin: "0" }}>Estado</p></div>
                        <div style={{ display: "flex", flex: "1", justifyContent: "start", width: "100%" }}><p style={{ margin: "0" }}>Fecha</p></div>
                        <div style={{ display: "flex", flex: "2", justifyContent: "start", width: "100%" }}><p style={{ margin: "0" }}>Usuario</p></div>
                    </div>
                    <div style={{ fontSize: "14px", color: "#5F6689", textAlign: "center", minHeight: "20px" }}>
                        {data.map((item, i) => (
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderTop: "1px solid #e5e9f0", padding: "8px 12px" }} key={i}>
                                <div style={{ display: "flex", flex: "2", justifyContent: "start", width: "100%", height: "fit-content" }}>{item.nqnEscuelaStatusCod === "C" ? "Cerrado" : item.nqnEscuelaStatusCod === "A" ? "Activo" : "Cerrado Temporalmente"}</div>
                                <div style={{ display: "flex", flex: "1", justifyContent: "start", width: "100%", height: "fit-content" }}>{moment(item.nqnEscuelaStatusFecha).format("DD/MM/YYYY").toString()}</div>
                                <div style={{ display: "flex", flex: "2", justifyContent: "start", width: "100%", height: "fit-content", textAlign: "start" }}>{`${item.usuarioNombre} - ${moment(item.nqnEscuelaStatusUFH).format("DD/MM/YYYY HH:mm:ss").toString()}`}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </Box>}
            <FormButton
                onClick={closeModal}
                oneButton="true"
                // inRequest={inRequest || isLoading}
                newFormData={{ button: "Salir" }}
                onCloseModal={closeModal}
            />
        </>
    );
}
