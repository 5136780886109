import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, StatusModal } from "./Actions";
import { Button, Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Cached, SearchRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import moment from 'moment';
import styles from "./Eventos.module.scss"
import XGrid from 'components/UI/Grid/XGrid';
import Filters from 'components/Filters';
import { useFormik } from 'formik';
import { escuelasGet } from 'services/services-mia/escuelasGet';
import { eventosGet } from 'services/services-mia/eventosGet';

export default function Eventos() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [escuelas, setEscuelas] = useState([])
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [forceUpload, setForceUpload] = useState(false);
    const service = useService()
    const credentials = useRecoilValue(userCredentials);
    const [isLoading, setIsLoading] = useState(false);
    const [eventos, setEventos] = useState([]);

    const cellBtn = (params) => {
        // console.log(params)
        return (
            <div className={styles.container}>
                <div className={styles.containerBtn}>
                    {params && params?.value?.map((item, index) => (
                        <div key={index} className={styles.containerData}>
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const titles = [
        {
            field: "cue",
            headerName: "CUE",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "escuelaNombre",
            headerName: "Escuela",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "fecha",
            headerName: "Fecha",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "docenteApellidos",
            headerName: "Apellidos",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "docenteNombre",
            headerName: "Nombres",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "docFormat",
            headerName: "DNI",
            flex: 1,
            minWidth: 120,
        },
        {
            field: "horas",
            headerName: "Eventos",
            flex: 2,
            minWidth: 120,
            renderCell: (params) => cellBtn(params)
        },
    ];

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const formik = useFormik({
        initialValues: {
            escuelaId: null,
            doc: "",
            fechaDesde: moment(new Date()).format('YYYY-MM-DD'),
            fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
        },
        onSubmit: (values) => {
            getEventosData()
        },
    });

    const getEventosData = async (hoy = false) => {
        setEventos([])
        setIsLoading(true)
        let result
        if (hoy) {
            result = await eventosGet(credentials, {
                escuelaId: formik.values.escuelaId,
                doc: "",
                fechaDesde: moment(new Date()).format('YYYY-MM-DD'),
                fechaHasta: moment(new Date()).format('YYYY-MM-DD'),
            })
        } else {
            result = await eventosGet(credentials, {
                escuelaId: formik.values.escuelaId,
                doc: formik.values.doc.replaceAll(".", ""),
                fechaDesde: formik.values.fechaDesde,
                fechaHasta: formik.values.fechaHasta,
            })
        }
        console.log(result)
        if (result[0]?.code !== 0) {
            const newRows = result ? result.map((item) => {
                return {
                    id: `${item.usuarioId}${item.nqnEscuelaCUE}${item.nqnPresentismoFH}`,
                    escuelaNombre: item.nqnEscuelaNombre,
                    cue: item.nqnEscuelaCUE,
                    docenteApellidos: item.usuarioApellidos,
                    docenteNombre: item.usuarioNombres,
                    doc: item.usuarioNroDeDocumento,
                    docFormat: new Intl.NumberFormat("de-DE").format(item.usuarioNroDeDocumento),
                    fecha: item.nqnPresentismoFH && item.nqnPresentismoFH,
                    horas: item.eventos,
                };
            }) : [];
            setEventos(newRows)
        }
        setIsLoading(false)
        const esc = await escuelasGet(credentials)
        setEscuelas(esc)
    }
    const resetForm = () => {
        formik.setFieldValue("doc", "")
        formik.setFieldValue("fechaDesde", moment(new Date()).format('YYYY-MM-DD'))
        formik.setFieldValue("fechaHasta", moment(new Date()).format('YYYY-MM-DD'))
        getEventosData(true)
    };
    useEffect(() => {
        getEventosData()
    }, [service, updateList, modalDetails.isOpen])
    return (
        <main className={s.main}>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "escuelaId", label: "Escuela", noSelect: true, options: escuelas, firtsOptionKey: "nqnEscuelaId", firstOptionName: "nqnEscuelaNombre", firstOptionValue: "nqnEscuelaId" },
                                { type: "text", name: "doc", label: "DNI" },
                                { type: "date", name: "fechaDesde", label: "Fecha desde", disabled: false },
                                { type: "date", name: "fechaHasta", label: "Fecha hasta", disabled: false },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <Button onClick={() => resetForm()} disabled={isLoading}>Borrar</Button>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => getEventosData()}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGrid
                    titles={titles}
                    rows={eventos}
                    loading={isLoading}
                    updateList={getEventosData}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    )
}
