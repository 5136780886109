import axiosPost from "./axiosPost";

export const docentesUpd = async (credentials, data) => {
    const body = {
        service: "NQNDocenteUpd",
        params: {
            nqnDocenteId: data?.userId,
            nqnDocenteStatus: data?.status, 
            nqnDocenteVersion: data?.version,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
