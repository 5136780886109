import React, { useEffect, useRef, useState } from "react";
import LogoQR from "assets/icons/QR.png";
import classes from "./Login.module.scss";
import QRCode from "react-qr-code";
import CircularIndeterminate from "components/utils/spinner/Spinner";
import { LinearProgress } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormikSelectInput } from "components/Filters/FormikInputs";
// Services
import { loginQREvent, loginQRGet, loginQRMerchant } from "services/services-mia/login";
import { RNServicioGet } from "services/services-mia/RNServicioGet";
import { usuarioMinimosGet } from "services/services-mia";
import { merchantLogoGet } from "services/services-mia/merchant";

import { checkAuth } from "services/checkAuth";
// Recoil
import {
    userCredentials,
    snackbarData,
    userInfo,
    rioNegroServices,
} from "recoilState/GlobalState";
import { useSetRecoilState } from "recoil";
import { LogoNQN, RNQR } from "assets/icons";
import { Routes } from "consts/Routes-App";
import { useLocation, useNavigate } from "react-router-dom";

export default function Login() {
    const setCredentials = useSetRecoilState(userCredentials);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setInfo = useSetRecoilState(userInfo);
    const setServices = useSetRecoilState(rioNegroServices);
    const navigate = useNavigate();
    const location = useLocation();

    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(false);
    const [expirado, setExpirado] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [coord, setCoord] = useState({});
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);
    const from =
        location.state?.from?.pathname + location.state?.from?.search ||
        Routes.dashboard.route;

    async function getQR(e) {
        if (e) e.preventDefault();
        setExpirado(false);
        setLoading(true);
        const { result } = await loginQRGet({
            sesionLatitud: coord.lat.toFixed(6),
            sesionLongitud: coord.lng.toFixed(6),
        });
        const data = result[0]
        setProgress(0);

        setUUID(data.codigoQR);
        setLoading(false);
    }

    useEffect(() => {
        if (checkAuth()) {
            navigate(from, { replace: true });
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) =>
                setSnackbarInfo({
                    message: "Error de localización",
                    open: true,
                    severity: "error",
                })
        );
        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 60 / 580 >= 100
                    ? 100
                    : oldProgress + ((60 / 580) * 100) / 60
            );
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        if (coord.lat && coord.lng) getQR();
    }, [coord]);


    useEffect(() => {
        if (UUID) {
            (async function getQREvent() {
                const { result: merchants, status } = await loginQREvent({
                    codigoQR: UUID,
                });
                console.log(merchants);
                if (status.code === 0) {
                    intentos.current++;
                    setUUID(null);
                    if (intentos.current > 2) {
                        setExpirado(true);
                    } else {
                        getQR();
                    }
                    return;
                }
                if (status.code === 1) {
                    if (merchants.length === 0) {
                        setSnackbarInfo({
                            open: true,
                            message: "Login no autorizado",
                            severity: "error",
                        });
                        setTimeout(() => {
                            setSnackbarInfo({
                                open: false,
                                message: "",
                                severity: "error",
                            });
                        }, 5000);
                        return;
                    }
                    if (merchants.length > 0) {
                        const esNqn = merchants.some((merchant) => merchant.merchantCod === "NQN")
                        if (!esNqn) {
                            setSnackbarInfo({
                                open: true,
                                message: "Login no autorizado",
                                severity: "error",
                            });
                            setTimeout(() => {
                                setSnackbarInfo({
                                    open: false,
                                    message: "",
                                    severity: "error",
                                });
                            }, 5000);
                        } else {
                            const { result: credentials, status } =
                                await loginQRMerchant({
                                    codigoQR: UUID,
                                    merchantId: merchants[0].merchantId,
                                });
                            if (status.code !== 1) {
                                setSnackbarInfo({
                                    open: true,
                                    message: "Login no autorizado",
                                    severity: "error",
                                });
                                setTimeout(() => {
                                    setSnackbarInfo({
                                        open: false,
                                        message: "",
                                        severity: "error",
                                    });
                                }, 5000);
                            }
                            sessionStorage.setItem(
                                "hooliToken",
                                credentials[0].hooliToken
                            );
                            sessionStorage.setItem(
                                "sessionId",
                                credentials[0].sessionId
                            );
                            sessionStorage.setItem(
                                "merchantId",
                                merchants[0].merchantId
                            );
                            sessionStorage.setItem(
                                "merchantNombre",
                                merchants[0].merchantNombre
                            );
                            sessionStorage.setItem(
                                "merchantRZ",
                                merchants[0].merchantRZ
                            );
                            sessionStorage.setItem(
                                "merchantCUIT",
                                merchants[0].merchantCUIT
                            );
                            const newCredentials = {
                                ...credentials[0],
                                ...merchants[0],
                            };
                            setCredentials(newCredentials);
                            const userData = await usuarioMinimosGet(newCredentials);
                            const { result: merchantData } = await merchantLogoGet({}, newCredentials);
                            setInfo({ ...merchantData[0], ...userData });
                            const { result: servicesData } = await RNServicioGet({}, newCredentials);
                            setServices(servicesData);
                            navigate(from, { replace: true });
                        }
                    }
                }
            })();
        }
    }, [UUID]);

    const formik = useFormik({
        initialValues: {
            merchant: null,
        },
        validationSchema: yup.object({
            merchant: yup.string().required("Seleccione un cliente"),
        }),
        onSubmit: async (values) => {
            const merchant = merchants.find(
                (merchant) => merchant.merchantId === parseInt(values.merchant)
            );
            const { result: credentials, status } = await loginQRMerchant({
                codigoQR: UUID,
                merchantId: merchant.merchantId,
            });
            if (status.code !== 1) {
                setSnackbarInfo({
                    open: true,
                    message: "Login no autorizado",
                    severity: "error",
                });
                setTimeout(() => {
                    setSnackbarInfo({
                        open: false,
                        message: "",
                        severity: "error",
                    });
                }, 5000);
            }
            sessionStorage.setItem("hooliToken", credentials[0].hooliToken);
            sessionStorage.setItem("sessionId", credentials[0].sessionId);
            sessionStorage.setItem("merchantId", merchant.merchantId);
            sessionStorage.setItem("merchantRZ", merchant.merchantRZ);
            sessionStorage.setItem("merchantNombre", merchant.merchantNombre);
            sessionStorage.setItem("merchantCUIT", merchant.merchantCUIT);
            const newCredentials = { ...credentials[0], ...merchant };
            setCredentials(newCredentials);
            const userData = await usuarioMinimosGet(newCredentials);
            const { result: merchantData } = await merchantLogoGet({}, newCredentials);
            setInfo({ ...merchantData[0], ...userData });
            const { result: servicesData } = await RNServicioGet({}, newCredentials);
            setServices(servicesData);
            navigate(from, { replace: true });
        },
    });
    return (
        <main className={classes.root}>
            <div className={classes.logoContainer}>
                <img src={LogoNQN} alt="logo-rn" />
            </div>
            <div className={classes.login}>
                <div className={classes.container}>
                    <div className={classes.qrContainer}>
                        {expirado ? (
                            <>
                                <h4>Ups!</h4>
                                <p>
                                    El código caducó, generá un{" "}
                                    <span>nuevo QR</span> para poder ingresar.
                                </p>
                                <img src={RNQR} alt="Celular" />
                                <button
                                    className={classes.button}
                                    onClick={getQR}
                                >
                                    Generar QR
                                </button>
                            </>
                        ) : (
                            <>
                                {!loading && (
                                    <>
                                        <h4>¡Hola!</h4>
                                        <p>
                                            Para poder ingresar, escanea el código QR desde la App de MIA Neuquén
                                        </p>
                                    </>
                                )}
                                {UUID && merchants.length === 0 && !loading && (
                                    <>
                                        <QRCode value={UUID} />
                                        <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                            style={{
                                                width: "100%",
                                                marginTop: "16px",
                                            }}
                                            color="secondary"
                                        />
                                    </>
                                )}
                                {loading && <CircularIndeterminate />}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
}
