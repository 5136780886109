import axiosPost from "./axiosPost";

export const escuelasGet = async (credentials, data) => {
    const body = {
        service: "NQNEscuelaGet",
        params: {
            nqnEscuelaId: data?.id,
            nqnEscuelaCUE: data?.cue,
            nqnDistritoId: data?.distritoId,
            nqnEscuelaStatusCod: data?.estado,
            nqnNivelModalidadId: data?.nivelModalidad,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
