import React, { useEffect, useRef, useState } from "react";
import { Grid, } from '@mui/material'
import { CircularIndeterminate, FormButton } from "components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import QRCode from "react-qr-code";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

export default function QrModal({ user, updateList, servicio, closeModal, setterRowSelected }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);
    const urlBase = 'https://ir.iduneuquen.miaid.me'
    const targetUrl = new URL(urlBase)
    targetUrl.searchParams.append('nqnEscuelaCUE', user.cue)
    targetUrl.searchParams.append('nqnEscuelaNombre', user.name)
    console.log(user)
    const [qr, setQr] = useState(targetUrl.href);
    const qrRef = useRef();
    function imprimirElemento(elemento) {
        console.log(elemento)
        var ventana = window.open('', 'PRINT');
        ventana.document.write('<html><head><title>' + document.title + '</title>');
        ventana.document.write('<style>@page{margin: 0}body{margin: 2cm}</style>');
        ventana.document.write('</head><body >');
        ventana.document.write(elemento.innerHTML);
        ventana.document.write('</body></html>');
        ventana.document.close();
        ventana.focus();
        ventana.print();
        ventana.close();
        closeModal()
        return true;
    }
    const printQr = () => {
        const element = document.getElementById("containerQr");
        imprimirElemento(element);
    };

    return (
        <div >
            <div  >
                <div id={"containerQr"} ref={qrRef} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%", height: "100%" }}>
                    <h3 style={{ fontFamily: "Lato, sans-serif", fontSize: "32px", width: "100%", display: "flex", justifyContent: "center" }}>{user.name}</h3>
                    {qr ?
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <QRCode value={qr} style={{ width: "400px", height: "400px" }} />
                        </div>
                        : <CircularIndeterminate />}
                    <p style={{ fontFamily: "Lato, sans-serif", fontSize: "20px", width: "100%", display: "flex", justifyContent: "center" }}>Escaneá este QR para realizar el registro</p>
                </div>
            </div>
            <FormButton
                // inRequest={inRequest || isLoading}
                onClick={printQr}
                newFormData={{ button: "Imprimir" }}
                onCloseModal={closeModal}
            />
        </div>
    );
}
