import React, { useCallback, useEffect, useRef, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorMessages";

import styles from "styles/pages/ModalActions.module.scss";
import { handleResponse } from "services/Local-services";
import { connectionIns, notificatorSend, paisGet, tipoDeDocumentoGet, usuarioBasicosGet } from "services/services-mia";

const sexos = [
    { sexoCod: "M", sexoNombre: "Masculino" },
    { sexoCod: "F", sexoNombre: "Femenino" },
    { sexoCod: "X", sexoNombre: "No Binario" }
];

const emailRegex = /^(([^<>()[\]\\.,;:#\s@"]+(\.[^<>()[\]\\.,;:#\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function InsertModal({ updateList, roles, servicio, closeModal }) {
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const credentials = useRecoilValue(userCredentials);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paises, setPaises] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);

    const [placeholderDNI, setPlaceholderDNI] = useState('')
    const [regexDNI, setRegexDNI] = useState(/./)

    const formikRef = useRef();

    const functions = {
        setSnackbarInfo,
        setInRequest,
        setIsOpen: closeModal,
        updateList,
    };

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });

    const getPais = useCallback(async () => {
        setIsLoading(true);
        const { result: paises, status } = await paisGet(credentials);
        if (status.code !== 1) {
            setIsLoading(false);
            return;
        }
        setPaises(paises);
        const paisId = await new Promise(((resolve) => {
            navigator.geolocation.getCurrentPosition((data) => {
                const userLat = data.coords.latitude;
                const userLng = data.coords.longitude;
                let paisIdSelected = paises[0].paisId;
                let Latitud = paises[0].paisLatitudCentro - userLat;
                let Longitud = paises[0].paisLongitudCentro - userLng;
                for (let i = 1; i < paises.length; i++) {
                    const { paisLatitudCentro: lat, paisLongitudCentro: lng } =
                        paises[i];
                    if (
                        Math.abs(lat - userLat + lng - userLng) <
                        Math.abs(Latitud + Longitud)
                    ) {
                        paisIdSelected = paises[i].paisId;
                        Latitud = lat - userLat;
                        Longitud = lng - userLng;
                    }
                }
                resolve(paisIdSelected)
            }, (err) => {
                console.warn(`ERROR(${err.code}): ${err.message}`);
                resolve(paises[0].paisId)
            });
        }));
        selectPais(paisId);
        setIsLoading(false);
        return paisId;
    }, [credentials]);

    const getTiposDeDocumento = useCallback(async (paisId) => {
        if (!paisId) return;
        setIsLoading(true);
        const { result, status } = await tipoDeDocumentoGet(
            paisId,
            credentials
        );
        if (status.code !== 1) {
            setIsLoading(false);
            return;
        }
        setTiposDeDocumento(result);
        selectTipoDeDocumento(result[0]);
        setIsLoading(false);
    }, [credentials]);

    useEffect(() => {
        if (Object.keys(credentials).length === 0) return;
        (async () => {
            const paisId = await getPais();
            await getTiposDeDocumento(paisId);
        })()
    }, [credentials, getPais, getTiposDeDocumento]);

    const selectPais = (paisId) => {
        formikRef.current.setFieldValue("pais", paisId)
    }

    const selectTipoDeDocumento = (tipoDeDocumento) => {
        formikRef.current.setFieldValue("tipoDeDocumento", tipoDeDocumento.tipoDeDocumentoCod);
        setPlaceholderDNI(tipoDeDocumento.tipoDeDocumentoPlaceholder)
        setRegexDNI(tipoDeDocumento.tipoDeDocumentoRegExp)
    }

    const handleChangePais = (event) => {
        const paisId = event.target.value;
        selectPais(paisId)
        getTiposDeDocumento(paisId);
    }

    const handleChangeTipoDeDocumento = (event) => {
        const tipoDeDocumentoCod = event.target.value;
        const tipoDeDocumento = tiposDeDocumento.find(td => td.tipoDeDocumentoCod === tipoDeDocumentoCod)
        selectTipoDeDocumento(tipoDeDocumento);
    }

    const handleConnection = async (values, resetForm, setFieldValue) => {
        setInRequest(true);
        const formattedDNI = values.dni.replace(/\D/g, "").slice(-8);
        const response = await usuarioBasicosGet({
            usuarioNroDeDocumento: formattedDNI,
            usuarioSexo: step.sexo ? values.sexo : null,
            usuarioFechaDeNacimiento: step.nacimiento ? values.fechaNacimiento : null,
        }, credentials);

        if (response.result.length === 0) {
            setStep({
                basico: false,
                sexo: false,
                nacimiento: false,
                contacto: true,
            })
            setInRequest(false);
            return;
        }
        if (response.result.length === 1) {
            const responseIns = await connectionIns(
                credentials.merchantId,
                servicio.servicioCod,
                response?.result[0] ? response.result[0].usuarioId : null,
                values.rol,
                values.tipoDeDocumento,
                values.dni,
                "S",
                values.email,
                credentials
            );

            handleResponse(
                responseIns,
                "Conexión creada exitosamente",
                functions
            );
            resetForm()
            return;
        }
        if (response.result.length > 1) {
            if (!step.sexo) {
                setFieldValue("sexo", "M");
                setStep({
                    basico: true,
                    sexo: true,
                    nacimiento: false,
                    contacto: false,
                })
                setInRequest(false);
                return;
            }
            if (!step.nacimiento) {
                setFieldValue("fechaNacimiento", "2000-01-01");
                setStep({
                    basico: true,
                    sexo: true,
                    nacimiento: false,
                    contacto: false,
                })
                setInRequest(false);
                return;
            }
        }
        setInRequest(false);
        return;
    };

    const handleConnectionContact = async (values, resetForm) => {
        setInRequest(true);
        const response = await connectionIns(
            credentials.merchantId,
            servicio.servicioCod,
            null,
            values.rol,
            values.tipoDeDocumento,
            values.dni,
            "S",
            values.email,
            credentials
        );

        handleResponse(
            response,
            "Conexión creada exitosamente",
            functions
        );
        if (response.status !== 1) return;

        notificatorSend(
            values.email,
            credentials.merchantNombre === "Policia de Colombia" ? "ponal" : "mia",
            [
                credentials.merchantNombre,
                values.nombre,
            ]
        );
        resetForm();
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                dni: "",
                email: "",
                sexo: "",
                rol: "",
                nombre: "",
                fechaNacimiento: "",
                tipoDeDocumento: "",
                pais: "",
            }}
            validationSchema={
                yup.object({
                    dni: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .matches(regexDNI, { message: "Número de documento inválido" }),
                    nombre: step.contacto
                        ? yup.string(FORM_ERROR_MESSAGES.text)
                            .required(FORM_ERROR_MESSAGES.required)
                        : yup.string(FORM_ERROR_MESSAGES.text)
                            .nullable(),
                    email: step.contacto
                        ? yup.string(FORM_ERROR_MESSAGES.text)
                            .required(FORM_ERROR_MESSAGES.required)
                            .matches(emailRegex, { message: "Email inválido" })
                        : yup.string(FORM_ERROR_MESSAGES.text)
                            .nullable(),
                    rol: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required)
                        .oneOf(roles.map((rol) => rol.rolCod)),
                })
            }
            onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                try {
                    if (step.basico) {
                        await handleConnection(values, resetForm, setFieldValue);
                    }
                    else if (step.contacto) {
                        await handleConnectionContact(values, resetForm, setFieldValue);
                    }
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="pais"
                            labelText="País"
                            onChange={handleChangePais}
                        >
                            {paises.map(({ paisId, paisNombre }) => (
                                <option
                                    key={paisId}
                                    data-id={paisId}
                                    value={paisId}
                                >
                                    {paisNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikSelectInput
                            fullWidth={true}
                            name="tipoDeDocumento"
                            labelText="Tipo de documento"
                            onChange={handleChangeTipoDeDocumento}
                        >
                            {tiposDeDocumento.map(({ tipoDeDocumentoCod, tipoDeDocumentoNombre }) => (
                                <option
                                    key={tipoDeDocumentoCod}
                                    data-id={tipoDeDocumentoCod}
                                    value={tipoDeDocumentoCod}
                                >
                                    {tipoDeDocumentoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                        <FormikTextInput
                            fullWidth={true}
                            name="dni"
                            placeholder={placeholderDNI}
                            labelText="Número de Documento"
                        />
                        <FormikSelectInput
                            fullWidth={true}
                            name="rol"
                            labelText="Rol"
                        >
                            {roles.map(({ rolCod, rolNombre }) => (
                                <option
                                    key={rolCod}
                                    data-id={rolCod}
                                    value={rolCod}
                                >
                                    {rolNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}

                    {step.sexo && <>
                        <FormikSelectInput
                            fullWidth={true}
                            name="sexo"
                            labelText="Sexo"
                        >
                            {sexos.map(({ sexoCod, sexoNombre }) => (
                                <option
                                    key={sexoCod}
                                    data-id={sexoCod}
                                    value={sexoCod}
                                >
                                    {sexoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>
                    </>}

                    {step.nacimiento && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="fechaNacimiento"
                            labelText="Fecha de nacimiento"
                            type="date"
                        />
                    </>}

                    {step.contacto && <>
                        <FormikTextInput
                            fullWidth={true}
                            name="nombre"
                            labelText="Nombre y apellido"
                            placeholder="Juan Galvan"
                        />
                        <FormikTextInput
                            fullWidth={true}
                            name="email"
                            labelText="Mail"
                            placeholder="ejemplo@gmail.com"
                        />
                    </>}

                    <FormButton
                        inRequest={inRequest || isLoading}
                        newFormData={{ button: "Guardar cambios" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
