import axiosPost from "./axiosPost";

export const eventosGet = async (credentials, data) => {
    const body = {
        service: "NQNEventoGet",
        params: {
            nqnEscuelaId: data?.escuelaId === null ? null : Number(data?.escuelaId),
            fechaDesde: data?.fechaDesde,
            fechaHasta: data?.fechaHasta,
            nqnDocenteNroDeDocumento: data?.doc,
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
