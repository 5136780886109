import axiosPost from "./axiosPost";

export const docentesIns = async (credentials, data) => {
    const body = {
        service: "NQNDocenteIns",
        params: {
            tipoDeDocumentoId: data?.tipoDoc,
            nqnDocenteNroDeDocumento: data?.doc.replaceAll(".", ""),
            nqnDocenteSexo: data?.sexo,
            nqnEscuelaId: data?.escuelaId,
            nqnCargoId: data?.cargoId,
            nqnDocenteHoras: Number(data?.horas),
            nqnDocenteHorasLu: Number(data?.horasLu),
            nqnDocenteHorasMa: Number(data?.horasMa),
            nqnDocenteHorasMi: Number(data?.horasMi),
            nqnDocenteHorasJu: Number(data?.horasJu),
            nqnDocenteHorasVi: Number(data?.horasVi),
        },
    };
    const result = await axiosPost(body, credentials);
    if(result?.status?.code === 0) {
        return [result.status]
    } else {
        return result.result;
    }
};
