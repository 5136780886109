import React from "react";
import {
    FormikDateInput,
    FormikSelectInput,
    FormikTextInput,
} from "./FormikInputs";
import { FiltrosStyles } from "pages/Filtros.styles";
import { Button } from "components";
export default function Filters({ formik, inputsRender, disabled }) {
    const styles = FiltrosStyles();
    const handleReset = () => {
        formik.resetForm();
    };
    return (
        <form className={styles.filtros} onSubmit={formik.handleSubmit}>
            {
                inputsRender.map((input, index) => (
                    <div key={index} className={styles.inputContainer}>
                        {
                            input.type.toLowerCase() === "text" && (
                                <FormikTextInput
                                    data={{
                                        [input.name]: {
                                            ...input
                                        }
                                    }}
                                    name={input.name}
                                    formik={formik}
                                    disabled={input.disabled}
                                    className={styles.input}
                                />
                            )
                        }
                        {
                            input.type.toLowerCase() === "select" && (
                                <FormikSelectInput
                                    data={{
                                        [input.name]: {
                                            ...input
                                        }
                                    }}
                                    name={input.name}
                                    formik={formik}
                                    disabled={input.disabled}
                                    options={input.options}
                                    optionKey={input.firstOptionKey}
                                    optionValue={input.firstOptionValue}
                                    optionName={input.firstOptionName}
                                    className={styles.input}
                                    noSelect={input.noSelect}
                                />
                            )
                        }
                        {input.type.toLowerCase() === "date" && (
                            <FormikDateInput
                                type="date"
                                variant="standard"
                                data={{
                                    [input.name]: {
                                        ...input
                                    }
                                }}
                                name={input.name}
                                formik={formik}
                                disabled={input.disabled}
                                className={styles.input}
                                handleChange={(e) => {
                                    formik.setFieldValue(input.name, e.target.value)
                                }}
                            />
                        )}
                    </div>
                ))
            }
            <Button type="submit" text="Filtrar" className={styles.button} disabled={disabled} />
            {/* <Button onClick={() => handleReset()} text="Borrar" className={styles.button} /> */}
        </form>
    );
};