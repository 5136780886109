import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { getFeriados } from 'services/services-mia'
import { Button } from "components";
import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, DeleteModal } from "./Actions";
import { Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, EditOutlined, DeleteOutlineRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import { useFormik } from 'formik';
import Filters from 'components/Filters';
import { feriadosSerieGrid as titles } from "consts/titulos-de-tablas";
import moment from 'moment';
import { getSerieFeriado } from 'services/services-mia/serieFeriadoGet';
import XGrid from 'components/UI/Grid/XGrid';

export default function FeriadosSerie() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [feriados, setFeriados] = useState([])
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [filter, setFilter] = useState([]);
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const service = useService()
    const credentials = useRecoilValue(userCredentials);

    const formik = useFormik({
        initialValues: {
            feriadoId: ""
        },
        onSubmit: (values) => {
            getFeriadosData()
        },
    });

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const getFeriadosData = async (reset) => {
        setFeriados([])
        setIsLoading(true)
        const result = await getSerieFeriado(credentials, { id: reset ? "" : formik.values.feriadoId })
        const filtros = await getFeriados(credentials)
        setFilter(filtros)
        const newRows = result[0]?.nqnFeriadoSerieId ? result.map((item) => {
            return {
                id: item.nqnFeriadoSerieId,
                name: item.nqnFeriadoNombre,
                type: item.nqnFeriadoTipo,
                typeName: item.nqnFeriadoTipo === "N" ? "Nacional" : item.nqnFeriadoTipo === "P" ? "Provincial" : "Jornada Pedagogica",
                date: item.nqnFeriadoSerieFecha ? moment(item.nqnFeriadoSerieFecha).format("DD/MM/YYYY").toString() : null,
                uid: item.nqnFeriadoUID,
                version: item.nqnFeriadoVersion,
            };
        }) : [];
        setFeriados(newRows)
        setIsLoading(false)
        return {
            rows: newRows,
            rowCount: newRows.length,
        };
    }
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };
    const resetForm = () => {
        formik.setFieldValue("feriadoId", "");
        getFeriadosData(true)
    };
    useEffect(() => {
        if (modalDetails.isOpen === false) {
            getFeriadosData()
        }
    }, [service, updateList, modalDetails.isOpen])
    // console.log(feriados)
    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions"]}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Filters
                        formik={formik}
                        inputsRender={
                            [
                                { type: "select", name: "feriadoId", label: "Feriado", noSelect: true, options: filter, firtsOptionKey: "nqnFeriadoId", firstOptionName: "nqnFeriadoNombre", firstOptionValue: "nqnFeriadoId" },
                            ]
                        }
                        disabled={isLoading}
                    />
                    <Button onClick={() => { resetForm() }} disabled={isLoading}>Borrar</Button>
                </div>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => getFeriadosData()}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Serie de Feriado",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear Serie de Feriado",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Serie de Feriado",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGrid
                    rows={feriados}
                    titles={titles}
                    loading={isLoading}
                    updateList={getFeriadosData}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    )
}
