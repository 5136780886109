import React, { useEffect, useRef, useState, version } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    snackbarData,
    userCredentials,
} from "recoilState/GlobalState";

import styles from "styles/pages/ModalActions.module.scss";
import { escuelasGet } from "services/services-mia/escuelasGet";
import { Box, Grid } from "@mui/material";
import { docentesGet } from "services/services-mia/docentesGet";
import { cargosGet } from "services/services-mia/cargosGet";
import { docentesUpd } from "services/services-mia/docentesUpd";

const sexo = [
    { value: "A", name: "Alta", id: "1" },
    { value: "B", name: "Baja", id: "2" },
];

export default function UpdateModal({ updateList, roles, user, closeModal, latitud, longitud }) {
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const [cargos, setCargos] = useState([]);
    const [escuelas, setEscuelas] = useState([]);

    const formikRef = useRef();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [inRequest, setInRequest] = useState(false);

    const [step, setStep] = useState({
        basico: true,
        sexo: false,
        nacimiento: false,
        contacto: false,
    });
    const getData = async () => {
        setIsLoading(true);
        const result = await docentesGet(credentials, { id: user.id })
        console.log(result);
        formikRef.current.setValues({
            status: result.result[0].nqnDocenteStatus,
            userId: result.result[0].nqnDocenteId,
            version: result.result[0].nqnDocenteVersion,
        });
        setData(result[0]);
        const esc = await escuelasGet(credentials)
        const car = await cargosGet(credentials)
        setEscuelas(esc);
        setCargos(car);
        setIsLoading(false);
    }
    const handleSubmit = async (values) => {
        const response = await docentesUpd(credentials, values)
        if (response.length === 0) {
            setSnackBarInfo({
                message: "Docente modificado correctamente",
                severity: "success",
                open: true,
            });
            formikRef.current.resetForm();
            updateList();
            closeModal();
        }
    };
    useEffect(() => {
        getData()
    }, []);
    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                status: "",
                userId: "",
                version: "",
            }}
            validationSchema={
                yup.object({
                    status: yup.string("Debe ingresar un estado").required("Debe ingresar un estado"),
                })
            }
            onSubmit={(values) => {
                handleSubmit(values);
            }}
        >
            {isLoading ? <CircularIndeterminate /> : <Form className={styles.inputs} noValidate>
                <div className={styles["column-sm"]}>
                    {step.basico && <Grid width={"100%"}>
                        <Box display={"flex"} width={"100%"} gap={4}>
                            <FormikSelectInput
                                fullWidth={true}
                                name="status"
                                labelText="Estado"
                                onChange={(e) => {
                                    formikRef.current.setFieldValue("status", e.target.value);
                                }}
                            >
                                <option value={""} selected disabled >
                                    Seleccione
                                </option>
                                {sexo.map(({ value, name }) => (
                                    <option
                                        key={value}
                                        data-id={value}
                                        value={value}
                                    >
                                        {name}
                                    </option>
                                ))}
                            </FormikSelectInput>
                        </Box>
                    </Grid>}
                    <FormButton
                        // inRequest={inRequest || isLoading}
                        newFormData={{ button: "Aplicar" }}
                        onCloseModal={closeModal}
                    />

                    {(inRequest || isLoading) && <CircularIndeterminate />}
                </div>
            </Form>}
        </Formik>
    );
}
