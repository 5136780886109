import React, { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';

import s from "styles/pages/Pages.module.scss"
import Modal from "components/UI/Modal/Modal";
import { InsertModal, UpdateModal, DeleteModal } from "./Actions";
import { Buttons, HeaderButton } from "components";
import { ACTIONS } from "consts/Actions";
import { Add, Cached, EditOutlined, DeleteOutlineRounded } from '@mui/icons-material';
import { useService } from 'components/route/ServiceRoute';
import { cargosGrid as titles } from "consts/titulos-de-tablas";
import { cargosGet } from 'services/services-mia/cargosGet';
import XGrid from 'components/UI/Grid/XGrid';

export default function Cargos() {
    const [modalDetails, setModalDetails] = useState({ isOpen: false });
    const [cargos, setCargos] = useState([])
    const [rowSelected, setRowSelected] = useState({ status: false, row: {} })
    const [connectionRoles, setConnectionRoles] = useState([]);
    const [forceUpload, setForceUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const service = useService()
    const credentials = useRecoilValue(userCredentials);

    const updateList = useCallback(() => { setForceUpload((value) => !value); }, [])

    const getCargosData = async () => {
        setCargos([])
        setIsLoading(true)
        const result = await cargosGet(credentials)
        console.log(result, "cargos")
        const newRows = result[0].nqnCargoId ? result.map((item) => {
            return {
                id: item.nqnCargoId,
                name: item.nqnCargoNombre,
                cod: item.nqnCargoCod,
                horas: item.nqnCargoHoras,
                tipo: item.nqnCargoTipo,
                tipoNombre: item.nqnCargoTipo === "C" ? "Horas catedra" : item.nqnCargoTipo === "S" ? "Semanales" : "Diarias",
                nivelModalidad: item.nqnNivelModalidadNombre,
                version: item.nqnCargoVersion,
                esDirectivo: item.nqnCargoEsDirectivo === "Y" ? "Si" : "No",
                esVisible: item.nqnCargoVisible === "Y" ? "Si" : "No",
            };
        }) : [];
        setCargos(newRows)
        setIsLoading(false)
        return {
            rows: newRows,
            rowCount: newRows.length,
        };
    }
    const handleClick = ({ procede, title }) => {
        setModalDetails({ procede, title, isOpen: true });
    };
    const closeModal = () => {
        setModalDetails({ isOpen: false });
    };

    useEffect(() => {
        if (modalDetails.isOpen === false) {
            getCargosData()
        }
    }, [service, updateList, modalDetails.isOpen])
    return (
        <main className={s.main}>
            <Modal open={modalDetails.isOpen} title={modalDetails.title} onClose={closeModal}>
                {modalDetails.procede === ACTIONS.UPDATE && (
                    <UpdateModal
                        user={rowSelected.row}
                        roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
                {modalDetails.procede === ACTIONS.DELETE && (
                    <DeleteModal
                        user={rowSelected.row}
                        servicio={service}
                        updateList={updateList}
                        closeModal={closeModal}
                        setterRowSelected={setRowSelected}
                    />
                )}
                {modalDetails.procede === ACTIONS.INSERT && (
                    <InsertModal
                        servicio={service}
                        // roles={connectionRoles}
                        updateList={updateList}
                        closeModal={closeModal}
                    />
                )}
            </Modal>
            <div className={s["filters-actions-right"]}>
                <Buttons>
                    <HeaderButton
                        text={"Actualizar"}
                        onClick={() => { getCargosData() }}
                        disabled={rowSelected.row.id || false}
                    >
                        <Cached />
                    </HeaderButton>
                    <HeaderButton
                        text={"Eliminar"}
                        handleClick={handleClick}
                        data={{
                            title: "Eliminar Cargo",
                            procede: ACTIONS.DELETE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <DeleteOutlineRounded />
                    </HeaderButton>
                    <HeaderButton
                        text={"Crear"}
                        handleClick={handleClick}
                        data={{
                            title: "Crear Cargo",
                            procede: ACTIONS.INSERT
                        }}
                        disabled={rowSelected.row.id}
                    >
                        <Add />
                    </HeaderButton>
                    <HeaderButton
                        text={"Editar"}
                        handleClick={handleClick}
                        data={{
                            title: "Editar Cargo",
                            procede: ACTIONS.UPDATE
                        }}
                        disabled={!rowSelected.row.id}
                    >
                        <EditOutlined />
                    </HeaderButton>
                </Buttons>
            </div>

            <div className={s.container}>
                <XGrid
                    rows={cargos}
                    titles={titles}
                    loading={isLoading}
                    updateList={getCargosData}
                    forceUpload={forceUpload}
                    secondarySelected={rowSelected}
                    setSecondarySelected={setRowSelected}
                />
            </div>
        </main>
    )
}
